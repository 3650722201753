import { SITE_IMG_PATH, SITE_URL } from '../../../../constants';

export const generateThumbnailImage = (image: string | undefined) => {
  const pattern = /^((http|https):\/\/)/;

  if (image) {
    return pattern.test(image) ? image : `${SITE_URL}${image}`;
  }

  return `${SITE_URL}${SITE_IMG_PATH}`;
};
