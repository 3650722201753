import React from 'react';

import CancelStyles from './Cancel.module.scss';

interface CancelProps {
  handleVideoClose: () => void;
}

const Cancel: React.FC<CancelProps> = ({ handleVideoClose }) => (
  <span data-testid="close-video-btn" className={CancelStyles.close} onClick={handleVideoClose} />
);

export default Cancel;
