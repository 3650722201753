import React, { useContext } from 'react';

import { Button as AntButton } from 'antd';

interface ButtonProps extends React.ComponentProps<typeof AntButton> {
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return <AntButton {...props}>{children}</AntButton>;
};

export default Button;
