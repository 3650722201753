import React from 'react';

import classNames from 'classnames';

import StripStyles from './Strip.module.scss';
import { IStrip } from './Strip.types';

const Strip: React.FC<IStrip> = ({
  variant,
  fluid,
  fullWidth,
  children,
  style,
  size,
  ...otherProps
}) => {
  return (
    <section
      className={classNames(StripStyles.strip, {
        [StripStyles[`strip_${variant}`]]: variant,
        [StripStyles[`strip_${size}`]]: size,
      })}
      {...otherProps}
      style={style}
    >
      <div
        className={classNames('container', {
          'container--fluid': fluid,
          'container--fullWidth': fullWidth,
        })}
      >
        {children}
      </div>
    </section>
  );
};

export default Strip;
