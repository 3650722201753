import React, { useContext } from 'react';

import classNames from 'classnames';

import { HeaderContext } from '../../common/Header/HeaderContext';
import DropdownMenu from '../DropdownMenu';

const Menu: React.FC = () => {
  const {
    menu,
    headerStyles,
    currentPath,
    isTransparent,
    openSubMenu,
    closeSubMenu,
    isMobile,
    subMenuState,
  } = useContext(HeaderContext) || {};

  // const [clientMenu, setClientMenu] = useState<typeof menu | null>(null);

  // useEffect(() => {
  //   setClientMenu(menu || []);
  // }, [menu, headerStyles]);

  // if (!clientMenu) {
  //   return <ul className={headerStyles.menu}></ul>; // Render empty `<ul>` to match SSR output
  // }

  return (
    <ul className={headerStyles.menu}>
      {menu?.length
        ? menu?.map((entry, index) => {
            const isActive = currentPath?.includes(entry.path);

            return (
              <li
                key={`${entry.label}-${index}`}
                className={classNames(headerStyles.menu__item, {
                  [headerStyles.active__link]: isActive,
                  [headerStyles.menu__item_light]: isTransparent,
                })}
                onClick={() => isMobile && openSubMenu?.(index)}
                onMouseEnter={() => !isMobile && openSubMenu?.(index)}
                onMouseLeave={() => !isMobile && closeSubMenu?.()}
                data-testid="nav-link"
              >
                <span
                  className={classNames('label', {
                    [headerStyles.item__active]:
                      currentPath?.includes(entry.path) || subMenuState?.[index],
                  })}
                >
                  {entry.label}
                </span>
                <DropdownMenu entry={entry} subMenuState={subMenuState?.[index] ?? false} />
              </li>
            );
          })
        : null}
    </ul>
  );
};

export default Menu;
