export const generateFooterData = (
  t: any,
  defaultPath: string,
  { isHomePage, isBestExecutionPage }: Record<string, boolean>
) => {
  let footerData = t('footer', { returnObjects: true });

  const bestExOverrides = t('best-execution.overrideFooter', { returnObjects: true });
  let extraInfo = '';
  let extraCopyright: any[] = [];

  const { footerExtraInfo } = footerData;

  if (isHomePage) {
    extraInfo = footerExtraInfo?.['home'];
  }

  if (isBestExecutionPage) {
    footerData = {
      ...footerData,
      copyright: footerData.copyright.map((copy: string | number) => bestExOverrides[copy] || copy),
    };
  }

  if (defaultPath?.includes('about-us')) {
    extraInfo = footerExtraInfo?.['about-us'];
  }

  if (defaultPath?.includes('compliance-regulatory')) {
    extraCopyright = footerExtraInfo?.['compliance-regulatory'];
  }

  if (defaultPath?.includes('transaction-cost-analysis')) {
    extraInfo = footerExtraInfo?.['transaction-cost-analysis'];
  }

  return {
    ...footerData,
    extraInfo,
    copyright: [
      ...(Array.isArray(footerData.copyright) ? footerData.copyright : []),
      ...(extraCopyright || []),
    ],
  };
};
