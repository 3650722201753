export const TABLET_WIDTH = 992;
export const MOBILE_WIDTH = 768;
export const MOBILE_WIDTH_SMALL = 576;
export const SCROLL_TRIGGER_POINT = -71;
export const CONTACT_OPTIONS = ['http', 'tel:', 'mailto:'];
export const SOCIALS = [
  {
    title: 'LinkedIn',
    link: 'https://www.linkedin.com/company/milltechfx/',
    src: '/images/icons/linkedin.svg',
  },
  {
    title: 'Facebook',
    link: 'https://www.facebook.com/milltechfx',
    src: '/images/icons/facebook.svg',
  },
  {
    title: 'Youtube',
    link: 'https://www.youtube.com/channel/UCVwyLOt3hZhLQd0-1D8aFtg',
    src: '/images/icons/youtube.svg',
  },
];
export const SITE_URL = 'https://milltechfx.com';
export const SITE_NAME = 'MillTechFX';
export const SITE_IMG_PATH = '/images/site/homepage.png';
export const SITE_IMG_PATH_FR = '/images/site/homepageFR.png';
export const MILLENNIUM_LINK = 'https://www.millenniumglobal.com/';
export const MILLENNIUM_TITLE = 'Millennium Global';
export const LARGE_STYLE_LANGUAGES = ['fr-FR'];
export const FONT_LINK =
  'https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;700&display=swap';
export const MAX_DOBSON = 'dobson';

export const COOKIE_BANNER_CLICK_TRACKER =
  'https://prod-12.uksouth.logic.azure.com:443/workflows/17d00f4527c54d9288b22ba8cd826713/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=C9i9CNkEkVOmj-_ep5sbX4FYhno7ap1y4P_0jYBiXRo';
export const EMAIL_VALIDATION_API_DEV =
  'https://prod-15.uksouth.logic.azure.com:443/workflows/e43110c9853d475da0b98242de907279/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Nix4sogvPE8A1jgVpeG32Yg20oYDiIGIMvhUrKpbCDY';
export const EMAIL_VALIDATION_API_PROD =
  'https://prod-19.uksouth.logic.azure.com:443/workflows/9171c6611e93481ca8e23844775c31eb/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ydX6n-Ld15nDn_5XQBdQxc862b2-KzQj9g93_1x3Lmw';
export const SUBMIT_FORM_API_DEV =
  'https://prod-25.uksouth.logic.azure.com:443/workflows/839b16538b7c4994940d0f4d841a9085/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=dIsujsLmOiqi1j6JZW5pvy1l30wcIBFv50TRi1YVxs0';
export const SUBMIT_FORM_API_PROD =
  'https://prod-29.uksouth.logic.azure.com:443/workflows/1caaf34655324d14bb5d9f1915245867/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=gN6qEEZ4LXk9xxDc05ovZeKW0ynoPiARNAv_h0Kr6is';

export const ENTITY_QUESTIONS = {
  what_is_the_principals_legal_name: 'What is the Principal’s Legal Name?',
  what_is_the_principals_legal_entity_identifier_lei:
    'What is the Principal’s Legal Entity Identifier (LEI)?',
};
