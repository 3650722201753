import React from 'react';

interface IOpenGraph {
  type: string;
  title: string;
  description: string;
  image: string;
  url: string;
}

export const generateMetaTags = (openGraph: IOpenGraph, isOpenGraphTag?: boolean) =>
  Object.entries(openGraph).map(([key, value]) => {
    const metaKey = isOpenGraphTag ? `og:${key}` : key;
    return <meta key={metaKey} name={metaKey} property={metaKey} content={value} />;
  });
