import * as React from 'react';

import menu from 'antd/es/menu';
import classNames from 'classnames';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { Link } from '../../elements';
import { generateFooterData } from '../Layout/utils';
import Strip from '../Strip';
import FooterStyles from './Footer.module.scss';
import { renderSocials } from './utils/renderSocials';

const Footer = ({ isHomePage, isBestExecutionPage }: any) => {
  const { t } = useTranslation('footer');
  const pathname = usePathname();
  const translation = generateFooterData(t, pathname, { isHomePage, isBestExecutionPage });
  const { menus, company, extraInfo, copyright } = translation;

  const [clientMenu, setClientMenu] = React.useState<typeof menu | null>(null);

  React.useEffect(() => {
    setClientMenu(menu || []);
  }, [menu]);

  if (!clientMenu) {
    return;
  }

  return (
    <Strip variant="blue" style={{ padding: 0 }}>
      <footer className={FooterStyles.footer}>
        <div className={FooterStyles.footer__columns}>
          <div className={FooterStyles.menu__wrapper}>
            {menus?.length
              ? menus?.map((menu: { title: any; items: any[] }, index: any) => (
                  <div key={`menu-${index}`} className={FooterStyles.footer__menu}>
                    <p>{menu.title}</p>
                    <ul>
                      {menu.items.map((link) => (
                        <li key={`page-${link.label}`}>
                          <Link to={link.path} title={link.title}>
                            {link.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))
              : null}
          </div>
        </div>
        <div
          className={classNames(FooterStyles.details, {
            [FooterStyles.withExtra]: extraInfo,
          })}
        >
          <div className={FooterStyles.contacts}>
            <ul>
              {company?.contact.map(
                (
                  item: {
                    icon: string | undefined;
                    label: string | undefined;
                    title: string | undefined;
                    path: string;
                  },
                  index: any
                ) => (
                  <li key={`contact-${index}`}>
                    {item.icon && (
                      <Image
                        src={item.icon}
                        alt={item.title || 'Image'}
                        title={item.title}
                        width={22}
                        height={16}
                      />
                    )}
                    <Link to={item.path} title={item.title}>
                      {item.label}
                    </Link>
                  </li>
                )
              )}
              <li className={FooterStyles.company__social}>{renderSocials()}</li>
            </ul>
          </div>
        </div>
        {extraInfo && (
          <div className={FooterStyles.extraInfo}>
            {extraInfo.map((item: any, index: any) => (
              <p key={`details-${index}`}>{item}</p>
            ))}
          </div>
        )}
        <div className={FooterStyles.footer__copyright}>
          {copyright.map((item: any, index: React.Key | null | undefined) => {
            const itemToDisplay = `${index === 0 ? `©${new Date().getFullYear()} ` : ''} ${item}`;
            return (
              <ReactMarkdown key={index} rehypePlugins={[rehypeRaw]}>
                {itemToDisplay}
              </ReactMarkdown>
            );
          })}
        </div>
      </footer>
    </Strip>
  );
};

export default Footer;
