import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { SCROLL_TRIGGER_POINT, TABLET_WIDTH } from '../../../constants';
import { useScrollPosition, useWindowSize } from '../../../helpers';
import { IHeader } from './Header.types';

interface HeaderProps {
  data: IHeader;
  currentPath: string;
  headerStyles: any;
  enableTransparentHeader?: boolean;
  children?: React.ReactNode;
}

interface HeaderContextType {
  menu: IHeader['menu'];
  toggleButton: IHeader['toggleButton'];
  contactButton: IHeader['contactButton'];
  loginButton: IHeader['loginButton'];
  openSubMenu: (index: number) => void;
  closeSubMenu: () => void;
  toggleLocalSubMenu: () => void;
  headerStyles: any;
  isTransparent: boolean;
  isMobile: boolean;
  subMenuState: boolean[];
  setMenuState: React.Dispatch<React.SetStateAction<boolean>>;
  localeSubMenuState: boolean;
  menuState: boolean;
  setSubMenuState: React.Dispatch<React.SetStateAction<boolean[]>>;
  initSubMenuList: boolean[];
  currentPath: string;
}

export const HeaderContext = React.createContext<HeaderContextType | null>(null);

const HeaderProvider: React.FC<HeaderProps> = ({
  data: { menu, toggleButton, contactButton, loginButton },
  currentPath,
  headerStyles,
  enableTransparentHeader,
  children,
}) => {
  const initSubMenuList = new Array((menu || []).length).fill(false);

  const windowSize = useWindowSize();
  const [menuState, setMenuState] = useState<boolean>(false);
  const [subMenuState, setSubMenuState] = useState<boolean[]>([...initSubMenuList]);
  const [localeSubMenuState, setLocaleSubMenuState] = useState<boolean>(false);
  const [topOfPage, setTopOfPage] = useState<boolean>(false);
  const [isMobile, setMobile] = useState<boolean>(false);

  const isTransparent = !!enableTransparentHeader && !topOfPage && !menuState && !isMobile;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if ((windowSize.width ?? 0) > TABLET_WIDTH) {
        setMenuState(false);
        setSubMenuState([...initSubMenuList]);
        setMobile(false);
      } else {
        setMobile(true);
      }
    }
  }, [windowSize, currentPath]);

  useScrollPosition(
    ({ currPos }: { currPos: { y: number } }) => {
      setTopOfPage(currPos.y < SCROLL_TRIGGER_POINT);
    },
    [setTopOfPage],
    undefined,
    false,
    100
  );

  const openSubMenu = (index: number) => {
    const newState = [...initSubMenuList];
    newState[index] = !subMenuState[index];
    setSubMenuState(newState);
  };

  const closeSubMenu = () => {
    subMenuState.includes(true) && setSubMenuState([...initSubMenuList]);
  };

  const toggleLocalSubMenu = () => {
    setLocaleSubMenuState((state) => !state);
  };

  return (
    <header
      className={classNames(headerStyles.header, {
        [headerStyles.box__shadow]: topOfPage,
        [headerStyles.transparent]: isTransparent,
      })}
    >
      <div className={classNames('container', headerStyles.header__container)}>
        <HeaderContext.Provider
          value={{
            menu,
            toggleButton,
            contactButton,
            loginButton,
            openSubMenu,
            closeSubMenu,
            toggleLocalSubMenu,
            headerStyles,
            isTransparent,
            isMobile,
            subMenuState,
            setMenuState,
            localeSubMenuState,
            menuState,
            setSubMenuState,
            initSubMenuList,
            currentPath,
          }}
        >
          {children}
        </HeaderContext.Provider>
      </div>
    </header>
  );
};

export default HeaderProvider;
