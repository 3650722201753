import React, { useContext } from 'react';

import classNames from 'classnames';

import { HeaderContext } from '../../common/Header/HeaderContext';

const BurgerMenu: React.FC = () => {
  const context = useContext(HeaderContext);

  if (!context) {
    throw new Error(
      'HeaderContext is null. Ensure that BurgerMenu is wrapped in a HeaderContext provider.'
    );
  }

  const { headerStyles, menuState, setMenuState, setSubMenuState, initSubMenuList, toggleButton } =
    context;

  return (
    <div className={headerStyles.menu__toggle}>
      <button
        onClick={() => {
          setMenuState((state) => !state);
          setSubMenuState([...initSubMenuList]);
        }}
        aria-label={toggleButton.title}
        aria-expanded={menuState}
        aria-controls="main-navigation"
        title={toggleButton.title}
        className={classNames(headerStyles.toggle__button, {
          [headerStyles.toggle__active]: menuState,
        })}
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  );
};

export default BurgerMenu;
