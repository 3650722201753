import { useInView, motion } from 'framer-motion';
import React, { useContext, useRef } from 'react';
import { MobileSizeContext } from '../../../helpers/MobileSizeContext';

const ProductAnimatedImage = ({ alt, src }: { alt?: string; src?: string }) => {
  const { isTablet } = useContext(MobileSizeContext);
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });

  return (
    <>
      {/* used to check if the component is in view */}
      <span ref={ref} style={{ visibility: 'hidden' }} />
      {isTablet ? (
        <img width={530} height={360} alt={alt} className="customImage" src={src} />
      ) : (
        <motion.img
          initial={{ x: '50px', opacity: 1 }}
          animate={{ x: inView ? 0 : '50px', opacity: inView ? 1 : 0 }}
          transition={{ duration: 1, delay: 0.2 }}
          width={530}
          height={360}
          alt={alt}
          className="customImage"
          src={src}
        />
      )}
    </>
  );
};

export default ProductAnimatedImage;
