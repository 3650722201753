const assetsUrl = 'https://website-admin.corp.milltechfx.com/assets/';
const blogImgPrest = '?key=blog-img&format=webp';

export const getAsset = (asset: Record<string, string>, enablePreset?: boolean) => {
  if (!asset || !asset.id) {
    return {
      path: '',
      alt: 'Undefined',
    };
  }

  return {
    path: `${assetsUrl}${asset.id}${enablePreset ? blogImgPrest : ''}`,
    alt: asset.title || 'Visual',
  };
};
