import * as React from 'react';

import Nav from '../Nav';
import HeaderStyles from './Header.module.scss';
import { IHeader } from './Header.types';
import HeaderProvider from './HeaderContext';

interface HeaderProps {
  data: IHeader;
  currentPath: string;
  enableTransparentHeader?: boolean;
}

const Header: React.FC<HeaderProps> = ({ data, currentPath, enableTransparentHeader }) => {
  return (
    <HeaderProvider
      data={data}
      currentPath={currentPath}
      headerStyles={HeaderStyles}
      enableTransparentHeader={enableTransparentHeader}
    >
      <Nav />
    </HeaderProvider>
  );
};

export default Header;
