import React, { useContext } from 'react';

import classNames from 'classnames';

import { HeaderContext } from '../../common/Header/HeaderContext';

const LangControl: React.FC = () => {
  const context = useContext(HeaderContext);

  if (!context) {
    throw new Error('HeaderContext must be used within a HeaderProvider');
  }

  const { headerStyles, localeSubMenuState } = context;

  return (
    <span className={headerStyles.locale__wrapper}>
      <div className={headerStyles.locale__img}>
        <img src={`/images/icons/locale-en-GB.svg`} width="16" alt={'en-GB'} />
      </div>

      <span className={classNames({ [headerStyles.item__active]: localeSubMenuState })}>
        {'en-GB'.split('-')[0].toLocaleUpperCase()}
      </span>
    </span>
  );
};

export default LangControl;
