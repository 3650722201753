import { useEffect } from 'react';

const GclidTracker = () => {
  useEffect(() => {
    // Check if window is defined (so if we're on the client-side)
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const gclid = urlParams.get('gclid');

      if (gclid) {
        // Store the gclid value wherever you need,
        // such as sending it to an analytics service, storing in local storage, etc.
        sessionStorage.setItem('gclid', gclid);
      }
    }
  }, []); // Empty dependency array means this useEffect runs once when component mounts

  return null; // This component doesn't render anything visible
};

export default GclidTracker;
