export const generateClassNames = (variant: string | string[], styles: any) => {
  const classNameVariants: Record<string, string> = {};

  if (variant && Array.isArray(variant)) {
    variant.forEach((entry) => {
      classNameVariants[styles[`link_${entry}`]] = entry;
    });
  } else if (variant) {
    classNameVariants[styles[`link_${variant}`]] = variant;
  }

  return classNameVariants;
};
