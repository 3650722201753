import { generateMetaTitle } from './generateMetaTitle';
import { generateCurrentPath } from './generateCurrentPath';
import { generateDefaultPath } from './generateDefaultPath';
import { generateMetaTags } from './generateMetaTags';
import { generateThumbnailImage } from './generateThumbnailImage';
import { generateFooterData } from './generateFooterData';

export {
  generateMetaTitle,
  generateCurrentPath,
  generateDefaultPath,
  generateMetaTags,
  generateThumbnailImage,
  generateFooterData,
};
