import React, { useContext } from 'react';

import { HeaderContext } from '../../common/Header/HeaderContext';
import Link from '../Link';

const Logo: React.FC = () => {
  const { isTransparent } = useContext(HeaderContext) || { isTransparent: false };

  return (
    <Link to="/" id="logo" aria-label="MillTechFX Home" title="MillTechFX Home">
      <div style={{ width: '109px', height: '34px', display: 'flex', alignItems: 'center' }}>
        <svg
          role="img"
          aria-hidden="true"
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 242.07 74.16"
        >
          <g>
            <path
              fill={!isTransparent ? '#0b3d5b' : '#fff'}
              d="M48.93,54.73h-8.85l-1.21-19.83c-.4-6.98-.76-14.37-.61-19.37l-8.65,34.04h-8.6L11.8,15.48c.25,6.37.15,12.9-.25,19.73l-1.16,19.58H1.53L5.32,7.33h11.69l8.45,33.23,7.94-33.29h11.84l3.69,47.45Z"
            />
            <path
              fill={!isTransparent ? '#0b3d5b' : '#fff'}
              d="M65.92,5.36c0,2.93-2.28,5.31-5.56,5.31s-5.51-2.33-5.51-5.31,2.28-5.36,5.51-5.36,5.56,2.33,5.56,5.36ZM64.81,54.73h-8.8V18.41h8.8v36.32Z"
            />
            <path
              fill={!isTransparent ? '#0b3d5b' : '#fff'}
              d="M72.3,46.44V3.84l8.8-.96v43.25c0,1.72.61,2.53,2.07,2.53.81,0,1.47-.15,2.07-.35l1.87,6.17c-1.52.71-3.59,1.21-5.82,1.21-5.67.1-9-3.29-9-9.26Z"
            />
            <path
              fill={!isTransparent ? '#0b3d5b' : '#fff'}
              d="M91.17,46.44V3.84l8.8-.96v43.25c0,1.72.61,2.53,2.07,2.53.81,0,1.47-.15,2.07-.35l1.87,6.17c-1.52.71-3.59,1.21-5.82,1.21-5.61.1-9-3.29-9-9.26Z"
            />
            <path
              fill={!isTransparent ? '#0b3d5b' : '#fff'}
              d="M138.82,14.52h-12.34v40.27h-9.16V14.52h-12.8v-7.23h35.21l-.91,7.23Z"
            />
            <path
              fill={!isTransparent ? '#0b3d5b' : '#fff'}
              d="M167.15,39.3h-22.81c.61,7.44,4.1,9.81,8.95,9.81,3.24,0,5.92-1.01,9.05-3.24l3.64,5.01c-3.59,2.88-7.99,4.86-13.46,4.86-11.28,0-17.3-7.49-17.3-18.97s5.82-19.42,16.14-19.42,15.98,6.88,15.98,18.31c0,1.21-.15,2.68-.2,3.64ZM158.6,33.18c0-5.97-2.12-9.56-7.08-9.56-4.1,0-6.73,2.73-7.23,9.97h14.27l.05-.4h0Z"
            />
            <path
              fill={!isTransparent ? '#0b3d5b' : '#fff'}
              d="M200.33,21.65l-3.9,5.31c-2.43-1.77-4.6-2.63-7.33-2.63-5.06,0-8.19,3.69-8.19,12.44s3.24,11.89,8.19,11.89c2.73,0,4.65-.91,7.33-2.63l3.9,5.56c-3.29,2.68-7.08,4.2-11.74,4.2-10.52,0-17.05-7.28-17.05-18.87s6.53-19.53,16.95-19.53c4.76,0,8.4,1.26,11.84,4.25Z"
            />
            <path
              fill={!isTransparent ? '#0b3d5b' : '#fff'}
              d="M234.98,28.43v26.3h-8.8v-24.94c0-4.55-1.77-5.82-4.45-5.82-3.09,0-5.51,2.12-7.64,5.41v25.34h-8.8V3.95l8.8-.91v19.73c2.83-3.44,6.17-5.36,10.57-5.36,6.47,0,10.32,4.15,10.32,11.03Z"
            />
          </g>
          <line
            fill="none"
            stroke="#21ffb2"
            strokeWidth="7.1"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="3.55"
            y1="70.61"
            x2="238.52"
            y2="70.61"
          />
        </svg>
      </div>
    </Link>
  );
};

export default Logo;
