import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { appWithTranslation, useTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';

import CookieBannerClickTracker from '../components/common/CookieBannerClickTracker/CookieBannerClickTracker';
import Footer from '../components/common/Footer';
import GclidTracker from '../components/common/GclidTracker/GclidTracker';
import Header from '../components/common/Header';
import { generateCurrentPath } from '../components/common/Layout/utils';
import { SITE_URL } from '../constants';
import '../styles/antReset.scss';
import './global.scss';

function MyApp({ Component, pageProps }: AppProps<any>) {
  const { t: headerT } = useTranslation('header');
  const { locale, pathname } = useRouter();
  dayjs.locale(locale);

  // http://localhost:3000/resources/glossary -> /resources/glossary
  const currentPath = generateCurrentPath(pathname);
  const canonicalUrl = `${SITE_URL}${currentPath.endsWith('/') ? currentPath : `${currentPath}/`}`;

  const isBestExecutionPage = [
    '/product/best-execution/',
    '/fr-FR/produit/best-execution/',
  ].includes(currentPath);
  const isHomePage = ['/', '/fr-FR/'].includes(currentPath);

  const headerData = headerT('header', { returnObjects: true }) as any;
  return (
    <ConfigProvider>
      <CookieBannerClickTracker />
      <GclidTracker />
      <Head>
        {currentPath && <link rel="canonical" href={canonicalUrl} />}
        {currentPath && <link rel="alternate" hrefLang="x-default" href={SITE_URL + currentPath} />}
      </Head>
      <Header data={headerData} currentPath={currentPath} enableTransparentHeader={isHomePage} />
      <Component {...pageProps} />
      <Footer isHomePage={isHomePage} isBestExecutionPage={isBestExecutionPage} />
    </ConfigProvider>
  );
}

export default appWithTranslation(MyApp);
