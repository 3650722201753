import React from 'react';

import classNames from 'classnames';
import { ChevronDown, ChevronRight } from 'react-feather';

import ChevronCircleStyles from './ChevronCircle.module.scss';

interface IChevronCircle {
  className?: string;
  hasDividers?: boolean;
  variant: string;
}

// eslint-disable-next-line react/display-name
const ChevronCircle = React.forwardRef(
  (
    { className, hasDividers, variant }: IChevronCircle,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => (
    <div ref={ref} className={classNames(ChevronCircleStyles.wrapper, className)}>
      {hasDividers && <div className={ChevronCircleStyles.divider} />}
      <div className={ChevronCircleStyles.chevron}>
        {variant === 'right' && <ChevronRight />}
        {variant === 'down' && <ChevronDown />}
      </div>
      {hasDividers && <div className={ChevronCircleStyles.divider} />}
    </div>
  )
);

export default ChevronCircle;
