import React, { useContext } from 'react';

import { isEmpty, omit, pick } from 'ramda';

import { ENTITY_QUESTIONS } from '../constants';

export const QuestionnaireContext = React.createContext(null);

export const useQuestionnaireContext = () => useContext(QuestionnaireContext);

export const parseQuestionnaireValues = <T extends Record<string, any>>(
  values: T,
  stringify = true
) => {
  if (values.additional_entities === 'Yes') {
    const entities = [pick(Object.keys(ENTITY_QUESTIONS), values), ...values.entities];
    return entities.map((entity) => ({
      ...Object.entries({
        ...omit([...Object.keys(ENTITY_QUESTIONS), 'entities', 'additional_entities'], values),
        ...entity,
      }).reduce((acc: Record<string, any>, [key, value]) => {
        acc[key] = Array.isArray(value) && stringify ? value.join('; ') : value;
        return acc;
      }, {} as Record<string, any>),
    }));
  }

  return [
    Object.entries(omit(['entities', 'additional_entities'], values)).reduce(
      (acc: Record<string, any>, [key, value]) => {
        acc[key] = Array.isArray(value) && stringify ? value.join('; ') : value;
        return acc;
      },
      {} as Record<string, any>
    ),
  ];
};

const mapQuestionsToIds = (questions: any[]) =>
  questions.reduce(
    (acc, { id, ...question }) => ({
      ...acc,
      [id]: question,
      ...(!isEmpty(question.dependentQuestion)
        ? mapQuestionsToIds(question.dependentQuestion)
        : {}),
    }),
    {}
  );

export const getQuestionsById = (sections: any[]) =>
  sections?.reduce((acc, { questions }) => ({ ...acc, ...mapQuestionsToIds(questions) }), {});

export const sanitizeForCSV = (dataArray: any[]) => {
  return dataArray.map((item) => {
    const sanitizedItem: Record<string, any> = {};
    for (const key in item) {
      let value = item[key];

      // Replace double quotes with single quotes
      value = value.replace(/"/g, "'");

      // Remove newlines and carriage returns
      value = value.replace(/(\r\n|\n|\r)/gm, '');

      // Trim whitespace
      value = value.trim();

      // Escape commas
      if (value.includes(',')) {
        value = `"${value}"`;
      }

      sanitizedItem[key] = value;
    }
    return sanitizedItem;
  });
};
