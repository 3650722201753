import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';

import { HeaderContext } from '../../common/Header/HeaderContext';
import Link from '../Link';
import { IDropdownProps } from './DropdownMenu.types';

const DropdownMenu: React.FC<IDropdownProps> = ({ entry, subMenuState }) => {
  const headerContext = useContext(HeaderContext);

  if (!headerContext) {
    throw new Error('HeaderContext is null. Ensure the provider is set.');
  }

  const { headerStyles, currentPath, setMenuState } = headerContext;

  return (
    <div
      className={classNames(headerStyles.menu__dropdown, {
        [headerStyles.menu__dropdown_active]: subMenuState,
      })}
      data-testid="dropdown"
    >
      <ul className={headerStyles.subMenu}>
        {entry?.items?.map((item) => {
          const normalizePath = (path: string) => path.replace(/\/+$/, ''); // Remove trailing slash

          const isActive =
            normalizePath(currentPath) === normalizePath(item.path) || // Exact match
            normalizePath(currentPath).startsWith(normalizePath(item.path) + '/'); // Subpath match only

          return (
            <li
              key={item.label}
              className={classNames(headerStyles.subMenu__item, {
                [headerStyles.item__active]: isActive,
              })}
            >
              <Link
                to={item.path}
                title={item.title}
                onClick={() => setMenuState(false)}
                data-testid="dropdown-label"
              >
                {item.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DropdownMenu;
