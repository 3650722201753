import React, { useContext } from 'react';

import classNames from 'classnames';

import { BurgerMenu, Link, Logo, Menu } from '../../elements';
import { HeaderContext } from '../Header/HeaderContext';

const Nav = () => {
  const { headerStyles, isTransparent, isMobile, menuState, contactButton, loginButton } =
    useContext(HeaderContext) || {};

  return (
    <>
      {isMobile && <Logo />}
      <nav
        className={classNames(headerStyles.navigation, {
          [headerStyles.navigation__active]: menuState,
        })}
        role="navigation"
      >
        <div className={headerStyles.nav__wrapper}>
          {!isMobile && <Logo />}
          <Menu />
        </div>

        <div className={headerStyles.nav__wrapper}>
          <div
            className={classNames(headerStyles.controls, {
              [headerStyles.controls_white]: !isTransparent,
            })}
          >
            <>
              <Link to={loginButton?.path || ''} title={loginButton?.title} variant="ghost">
                {loginButton?.title}
              </Link>
              <Link to={contactButton?.path || ''} title={contactButton?.title} variant="mint">
                {contactButton?.title}
              </Link>
            </>
          </div>
        </div>
      </nav>
      {isMobile && <BurgerMenu />}
    </>
  );
};

export default React.memo(Nav);
