import React from 'react';

import classNames from 'classnames';

import ToggleArrowStyles from './ToggleArrow.module.scss';

interface ToggleArrowProps {
  isActive?: boolean;
  isDark: boolean;
}

const ToggleArrow: React.FC<ToggleArrowProps> = ({ isActive, isDark }) => (
  <div className={ToggleArrowStyles.wrapper}>
    <i
      className={classNames(ToggleArrowStyles.arrow, {
        [ToggleArrowStyles.active]: isActive,
        [ToggleArrowStyles.dark]: isDark,
      })}
      data-testid={isDark ? 'dark-chevron' : 'light-chevron'}
    ></i>
  </div>
);

export default ToggleArrow;
