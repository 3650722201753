import React from 'react';

import classNames from 'classnames';
import NextLink from 'next/link';
import { Link as ScrollLink } from 'react-scroll';

import { CONTACT_OPTIONS } from '../../../constants';
import LinkStyles from './Link.module.scss';
import { generateClassNames } from './utils/generateClassNames';

interface LinkProps {
  to: string;
  scroll?: boolean;
  variant?: string | string[];
  title?: string;
  [additionalProp: string]: any;
}

const LocalizedLink: React.FC<{ href: string; children: React.ReactNode; className?: string }> = ({
  href,
  children,
  className,
  ...rest
}) => {
  return (
    <NextLink href={href} className={className} {...rest}>
      {children}
    </NextLink>
  );
};

const Link: React.FC<LinkProps> = ({
  children,
  to,
  scroll,
  variant,
  title,
  isLocalized = true,
  ...otherProps
}) => {
  if (!to) return null;

  const isContactLink = CONTACT_OPTIONS.some((string) => to.includes(string));
  const classNameVariants = generateClassNames(variant || '', LinkStyles);
  const isButton = to === 'button';

  const classes = classNames(LinkStyles.link, 'link', classNameVariants);

  if (isButton) {
    return (
      <button
        aria-label={`LinkButton ${title && `to ${title}`}`}
        className={classes}
        type="button"
        title={title || 'Link'}
        {...otherProps}
      >
        {children}
      </button>
    );
  }

  if (isContactLink || !isLocalized) {
    return (
      <a
        className={classes}
        href={to}
        target="_blank"
        rel="noreferrer noopener nofollow"
        title={title || 'Link'}
      >
        {children}
      </a>
    );
  }

  if (scroll) {
    return (
      <ScrollLink to={to} className={classes} smooth={true} {...otherProps}>
        {children}
      </ScrollLink>
    );
  }

  return (
    <LocalizedLink href={to} className={classes} {...otherProps}>
      {children}
    </LocalizedLink>
  );
};

export default Link;
