import React from 'react';

import classNames from 'classnames';
import { XCircle as KillButton } from 'react-feather';

import PillStyles from './Pill.module.scss';

interface IPill {
  label: string;
  id?: string;
  onKillPill?: (value: string) => void;
}

const Pill = ({ label, id, onKillPill }: IPill) => {
  return (
    <div
      className={classNames(PillStyles.pill, {
        [PillStyles[`pill_withKill`]]: !!onKillPill,
      })}
    >
      {label}
      {!!onKillPill && (
        <KillButton
          onClick={() => onKillPill(id || '')}
          className="pillKill"
          width={12}
          height={12}
          color="#083048"
          cursor="pointer"
        />
      )}
    </div>
  );
};

export default Pill;
