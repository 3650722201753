import { useEffect } from 'react';

import { COOKIE_BANNER_CLICK_TRACKER } from '../../../constants';

const CookieBannerClickTracker = () => {
  const onSubmit = async (accepted: boolean) => {
    try {
      const response = await fetch(COOKIE_BANNER_CLICK_TRACKER, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accepted }),
      });
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const acceptButton = document.getElementById('hs-eu-confirmation-button');
      const declineButton = document.getElementById('hs-eu-decline-button');

      // Handler for accept button
      const handleAccept = () => onSubmit(true);
      // Handler for decline button
      const handleDecline = () => onSubmit(false);

      // Attach event listeners
      if (acceptButton) acceptButton.addEventListener('click', handleAccept);
      if (declineButton) declineButton.addEventListener('click', handleDecline);

      // Cleanup function to remove event listeners
      return () => {
        if (acceptButton) acceptButton.removeEventListener('click', handleAccept);
        if (declineButton) declineButton.removeEventListener('click', handleDecline);
      };
    }
  }, []); // Empty dependency array means this effect runs only once after the initial render

  return null; // This component doesn't render anything itself
};

export default CookieBannerClickTracker;
