import React, { useContext, useRef } from 'react';

import { motion, useInView } from 'framer-motion';
import { AnimationProps } from 'framer-motion';
import Image from 'next/image';

import { MobileSizeContext } from '../../../helpers/MobileSizeContext';
import ProductImageStyles from './ProductImage.module.scss';

type Props = {
  alt?: string;
  src?: string;
  srcMobile?: string;
  getImageAnimation?: (isInView: boolean) => AnimationProps;
};

const ProductImage = ({ alt, src, srcMobile, getImageAnimation = () => ({}) }: Props) => {
  const { isTablet } = useContext(MobileSizeContext);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <>
      {/* used to check if the component is in view */}
      <span ref={ref} style={{ visibility: 'hidden' }} />
      {isTablet ? (
        <Image
          alt={alt || 'image'}
          src={srcMobile || ''}
          className={ProductImageStyles.productImage}
          fill={true}
        />
      ) : (
        <motion.img
          // need this visibility animation to start the gif animation when the component is in view
          initial={{ visibility: 'hidden' as const, opacity: 0 }}
          animate={{
            visibility: isInView ? ('visible' as const) : ('hidden' as const),
            opacity: isInView ? 1 : 0,
          }}
          transition={{ duration: 0.1 }}
          {...getImageAnimation(isInView)}
          className={ProductImageStyles.productImage}
          alt={alt}
          src={src}
        />
      )}
    </>
  );
};

export default ProductImage;
