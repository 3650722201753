import * as React from 'react';

import { Link } from '../../../elements';
import { SOCIALS } from '../../../../constants';

export const renderSocials = () => {
  return SOCIALS.map((social) => (
    <Link to={social.link} key={social.title}>
      <img src={social.src} alt={social.title} height="14" width="14" title={social.title} />
    </Link>
  ));
};
