import React from 'react';

import { MOBILE_WIDTH, MOBILE_WIDTH_SMALL, TABLET_WIDTH } from '../constants';
import { useWindowSize } from './useWindowSize';

interface IMobileSizeContext {
  isTablet: boolean;
  isMobile: boolean;
  isMobileSmall: boolean;
}

export const MobileSizeContext = React.createContext<IMobileSizeContext>({
  isTablet: false,
  isMobile: false,
  isMobileSmall: false,
});

interface IMobileContext {
  children: React.ReactNode;
}

export const MobileContextProvider: React.FC<IMobileContext> = ({ children }) => {
  const windowSize = useWindowSize();
  const isTablet = !((windowSize.width ?? 0) > TABLET_WIDTH);
  const isMobile = !((windowSize.width ?? 0) > MOBILE_WIDTH);
  const isMobileSmall = !((windowSize.width ?? 0) > MOBILE_WIDTH_SMALL);

  return (
    <MobileSizeContext.Provider
      value={{
        isTablet,
        isMobile,
        isMobileSmall,
      }}
    >
      {children}
    </MobileSizeContext.Provider>
  );
};
